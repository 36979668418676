import axios from 'axios'
const API = require('../util/API');

export default {
    getChannelDetails () {
        return new Promise( (resolve,reject) => {
            API.get('/stream/getdata').then((response) => {
                let {getMatches} = response.data.data
                resolve(getMatches)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    get_ipAddress () {
        return new Promise( (resolve,reject) => {
            API.get('/get_ip',{headers: { 
                'Content-Type': 'application/json',
            }}).then((response) => {
                console.log(response.data.data)
                resolve(response.data.data)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}