import React, { Component } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import LIVESTREAM from '../../services/channel';
import * as _loadash from 'lodash';
import { SOCCER, HORSE_RACING, CRICKET, BASEBALL, PLAYERURL, DEFAULT } from '../../constant/index'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './video.scss'
import "video-react/dist/video-react.css";
import "./home.scss"
import Collapsible from 'react-collapsible';

// const whitelistURL = [
//   'www.google.com'
// ]
const whitelistURL = [
  'bbb.games',
  'www.bbb.games',
  'www.pre.snklab.com',
  'www-new-918.bbb.games',
  'www.9bet.games',
  'www.olaexch.com',
  'ec2-3-135-101-58.us-east-2.compute.amazonaws.com',
  'www.moon777.games',
  'www.skybet777.com',
  'www.wagenwin.com',
  'www.cbtfcasino247.com',
  'www.exch111.com',
  'www.betdaily.com',
  'www.pre.bbb.games',
  'www.lttexch.com',
  'www.eu.betdaily.com',
  'www.999bet.games',
  'www.betdailyexch.com',
  'eu.betdailyexch.com',
  'www.33exch.com',
  'www.mahadevbet.in',
  'www.preprod.livingstreamvdo.com',
  'www.mpc999.com',
  'mpc999.com',
  'www.client.bbb365.link',
  '9bets.xyz',
  'www.9bets.xyz',
  'www.mpc123.link', ,
  'www.preprod.mpc999.com',
  'www.preprod.9bets.xyz',
  'www.9bets.link',
  'www.mahadev.link',
  'www.bbbgames.xyz',
  'bbbgames.xyz',
  'www.mahadevbet.xyz',
  'mahadevbet.xyz',
  'parimatch9.link',
  'preprod.parimatch.exchange',
  'parimatch.exchange',
  'www.parimatch.exchange',
  'iframe.mpc123.link',
  'iframe.bbb365.link',
  'bbbiframe.com',
  'preprod.bbbiframe.com',
  'preprod.iceexch.in',
  'iceexch.in',
  'www.iceexch.in',
  'skygamex.com',
  'www.skygamex.com',
  'preprod.skygamex.com',
  'preprod.9bet.games',
  '9bet.games',
  'www.9bet.games',
  'test.super7s.com',
  'super7s.com',
  'www.super7s.com',
  'm.super7s.com',
  'preprod.super7s.com',
  'preprod-m.super7s.com',
  'preprod.iexch.vip',
  'preprod-m.iexch.vip',
  'iexch.com',
  'www.iexch.com',
  'm.iexch.com',
  'gamet.bbbiframe.com',
  'preprod-gamet.bbbiframe.com',
  'parimatch.bbbiframe.com',
  'preprod-parimatch.bbbiframe.com',
  'test.iexch.com',
  'test-m.iexch.com',
  'test2.iexch.com',
  'strikers365.net',
  'www.strikers365.net',
  'm.strikers365.net',
  'preprod.strikers365.net',
  'preprod-m.strikers365.net',
  'jsbexchange.games',
  'www.jsbexchange.games',
  'preprod.jsbexchange.games',
  'yolo247.com',
  'www.yolo247.com',
  'm.yolo247.com',
  'www.yolo247.co.in',
  'm.yolo247.co.in',
  'preprod.yolo247.com',
  'preprod-m.yolo247.com',
  'preprod.yolo247.co.in',
  'preprod-m.yolo247.co.in',
  'i88.bbbiframe.com',
  'preprod-i88.bbbiframe.com',
  'betbullsbook.com',
  'www.betbullsbook.com',
  'preprod.betbullsbook.com',
  'preprod.yolo247.club',
  'preprod-m.yolo247.club',
  'www.yolo247.club',
  'm.yolo247.club',
  'bbb.site',
  'www.bbb.site',
  'www.betdaily.com',
  'm.betdaily.com',
  'preprod.betdaily.com',
  'preprod-m.betdaily.com',
  'www.betdaily.club',
  'm.betdaily.club',
  'preprod.betdaily.club',
  'preprod-m.betdaily.club',
  'preprod.betdaily.bet',
  'betdaily.bet',
  'www.betdaily.bet',
  'preprod.betdaily.games',
  'betdaily.games',
  'www.betdaily.games',
  'k9win.bbbiframe.com',
  'preprod-k9win.bbbiframe.com',
  'www.betdaily.io',
  'm.betdaily.io',
  'corbet247.games',
  'www.corbet247.games',
  'preprod.corbet247.games',
  'm.baaziadda.com',
  'www.baaziadda.com',
  'www.yolo247.net',
  'm.yolo247.net',
  'www.fomo7.com',
  'm.fomo7.com',
  'www.yolo247.co',
  'm.yolo247.co',
  'www.yolo247.live',
  'm.yolo247.live',
  'www.yolo247.vip',
  'm.yolo247.vip',
  'preprod.yolo247.net',
  'preprod-m.yolo247.net',
  'www.fomo7.xyz',
  'm.fomo7.xyz',
  'www.fun88.site',
  'm.fun88.site',
  'www.fun88.online',
  'm.fun88.online',
  'www.fomo7.pro',
  'm.fomo7.pro',
  'www.fomo7.club',
  'm.fomo7.club',
  'www.gofun88.in',
  'm.gofun88.in',
  'preprod.gofun88.in',
  'preprod-m.gofun88.in'
]
const SECRET_KEY2 = "7d6e75a3fc8b4720ab5adb81ad190be7";
class Home extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      source: '',
      liveStreamData: [],
      playVideoUrl: '',
      update: '',
      loading: false,
      currentSports: "",
      collapse: false,
      error: false,
      noData: false,
      ipAddress: ''
    };
    this.setState = this.setState.bind(this);
    this.setIcon = this.setIcon.bind(this);
    this.changeSource = this.changeSource.bind(this);
    this.alternativeIpFinder = this.alternativeIpFinder.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    let ref = document.referrer
    let domain = ref.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
    // window.innerWidth < 600 || whitelistURL.indexOf(domain) > -1
    if (whitelistURL.indexOf(domain) > -1) {
      // const url = "https://api.ipdata.co/?api-key=4bf95bafac0f84229d80e1ca593692b046901704044c35e312183a33"
      const url = ` https://ipgeolocation.abstractapi.com/v1/?api_key=${SECRET_KEY2}`
      fetch(url)
        .then(res => res.json())
        .then(data => this.setState({ ipAddress: data.ip_address }, () => {
          if (data.error) {
            // Fallback api
            this.alternativeIpFinder()
          }
          let customSort = {
            'CRICKET': 1,
            'FOOTBALL': 2,
            'TENNIS': 3,
            'ESPORTS': 4,
            'BASKETBALL': 5
          }
          LIVESTREAM.getChannelDetails()
            .then((response) => {
              let filterByActive = response.filter((each) => {
                return each.bid === parsed.eventId
              })
              console.log('FIlter', filterByActive)
              if (response.length === 0 || filterByActive.length === 0) {
                this.setState({ noData: true })
              }
              let sortObj = _loadash.sortBy(filterByActive, function (obj) {
                return customSort[obj.Type];
              });
              let groupByData = _loadash.groupBy(sortObj, 'Type')
              let pairs = _loadash.toPairs(groupByData)
              console.log('Prainr', pairs)
              let channelId = pairs[0][1][0].Channel
              this.setState({ liveStreamData: pairs, playVideoUrl: PLAYERURL + `${'chid=' + channelId + '&ip=' + this.state.ipAddress}` }, () => {
              })
            })
            .catch((error) => {
              this.setState({ error: true })
            })
        }))
    } else {
      this.setState({ noData: true })
    }
  }

  alternativeIpFinder() {
    fetch('https://ipapi.co/json')
      .then(res => res.json())
      .then(data => this.setState({ ipAddress: data.ip }))
  }


  changeSource(name, currentSports) {
    let elem = document.getElementById('one')
    elem.click()
    if (name !== this.state.source) {

      this.setState({
        source: name,
      }, () => {
        this.setState({ currentSports: currentSports.sport })
      });
    }
  }


  setVideo(sport) {
    this.setState({ collapse: false, playVideoUrl: PLAYERURL + `${'chid=' + sport.Channel + '&ip=' + this.state.ipAddress}` })
    let elem = document.getElementById('one')
    elem.click()
  }

  setIcon(video) {
    switch (video[0]) {
      case SOCCER: return 'sports_soccer'
        break;
      case HORSE_RACING: return 'sports_horse_racing'
        break;
      case CRICKET: return 'sports_cricket'
        break;
      case BASEBALL: return 'sports_baseball'
        break;
      default: return 'sports_default'
        break
    }
  }


  openDiv() {
    return (
      <div className="accordion">
        <div className="live_heading">
          <div className="accordion__button" id="one">
            <span className="onlyDesktopView">Select Live Channel - Currently Playing {this.state.currentSports}</span>
            <span className="onlyMobileView">Playing {this.state.currentSports}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="live_stream">
        {
          this.state.noData && <div className="noDataContainer"><h1>No Live Stream Available ...</h1></div>
        }
        <iframe className="responsive-iframe" src={this.state.playVideoUrl}></iframe>
      </div>
    );
  }
}

export default withRouter(Home)
